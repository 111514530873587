import React, { useState } from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography, TableRow, TableCell, TableContainer, Paper, Table, TableHead, TableBody, AppBar, Toolbar, List, ListItem, ListItemText, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, FormControl } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    flexGrow: 1,
  },
  mainContent: {
    marginTop: "5px",
    marginBottom: "60px"
  },
  footer: {
    padding: "10px",
    position: "fixed",
    height: "60px",
    bottom: "5px",
    width: '100%'
  },
  faq: {
    textAlign: "left",
    padding: "5px"
  },
  helper: {
    textAlign: "left"
  },
  solicit: {
    marginTop: "20px"
  }
}));

function App() {
  const classes = useStyles();
  const [modifier, setModifier] = useState('');
  const [query, setQuery] = useState('');
  const [verb, setVerb] = useState('');
  const [fetching, setFetching] = useState(false);
  const [results, setResults] = useState({} as { [key: string]: any });
  const [error, setError] = useState('')
  const [solicit, setSolicit] = useState(false);

  const submit = async () => {
    setFetching(true)
    setSolicit(true)
    setResults({})
    setError('')
    const res = await fetch(`/api/v?m=${modifier}&v=${verb}`);
    setFetching(false)
    if (res.status === 200) {
      const data = await res.json();
      setQuery(data.query)
      setResults(JSON.parse(data.res))
    } else if (res.status === 400) {
      const data = await res.json();
      setError(data)
    } else {
      setError("Something went wrong! Check your spelling? Otherwise please try again later. If the problem persists, contact me...")
    }
  }

  const fetchingEl = (
    <div>
      <Typography color="secondary" gutterBottom>
        Fetching...
      </Typography>
      <Typography>
        While you're waiting, <a href={`https://www.thesaurus.com/browse/${verb}`} target="_blank" rel="noopener noreferrer">here's what the thesaurus has to say.</a>
      </Typography>
    </div>
  )

  const resultsNodes = Object.keys(results).map((k, i) => {
    return (
      <TableRow key={i}>
        <TableCell><a href={`https://www.dictionary.com/browse/${k}`} target="_blank" rel="noopener noreferrer">{k}</a></TableCell>
        <TableCell>{results[k]}</TableCell>
      </TableRow>
    )
  })

  const resultsEl = Object.keys(results).length > 0 ? (
    <div>
      <Typography color="secondary">
        <em>Query:</em> { query }
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Word</TableCell>
              <TableCell>Match (lower is better)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultsNodes}
          </TableBody>
        </Table>
      </TableContainer>

      <Paper>
        <Typography>
          Don't like any of these? Here are other options: 
        </Typography>
        <List dense={true}>
          <ListItem>
            <a href={`https://www.thesaurus.com/browse/${verb}`} target="_blank" rel="noopener noreferrer">
              <ListItemText
                primary="thesaurus.com"
              />
            </a>
          </ListItem>
          <ListItem>
            <a href={`https://www.onelook.com/reverse-dictionary.shtml?s=${modifier}%20${verb}`} target="_blank" rel="noopener noreferrer">
              <ListItemText
                primary="onelook.com"
              />
            </a>
          </ListItem>
          <ListItem>
            <a href={`https://www.wordnik.com/words/${verb}#relate`} target="_blank" rel="noopener noreferrer">
              <ListItemText
                primary="wordnik.com"
              />
            </a>
          </ListItem>
        </List>
      </Paper>
    </div>
  ) : null;

  const errorEl = error !== null ? (
    <div>
    <Typography color="secondary" gutterBottom>
      <em>Query:</em> { query }
    </Typography>
    <Typography color="error">
      { error }
    </Typography>
    </div>

  ) : null;

  const solicitEl = (
    <Grid container className={classes.solicit}>
      <Grid item xs={12} md={6}>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="ULH3ZHQKWXL38" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button variant="contained" color="secondary" href="https://share.hsforms.com/1l2FlY5BDTeObsVEuiqzKnw4mj2d" target="_blank" rel="noopener noreferrer">
          Subscribe for updates
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">
            StrongVerbs.ai v0.1
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} className={classes.mainContent}>
        <Grid item xs={12} md={6}>
          <Card raised>
            <CardContent>
              <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <TextField variant="outlined" id="modifier" label="Modifier" placeholder="excitedly" onChange={(e) => setModifier(e.target.value)} />
                      <FormHelperText className={classes.helper}>Works best if you use an adverb: e.g., excitedly, strongly.</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <TextField variant="outlined" id="verb" label="Verb" placeholder="laughed" onChange={(e) => setVerb(e.target.value)} />
                      <FormHelperText className={classes.helper}>Works best if you use past tense: e.g., launched, punched.</FormHelperText>
                    </FormControl>
                  </Grid>
                {/* <p>The current time is { currentTime }.</p> */}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Button variant="contained" color="primary" onClick={submit}>
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised>
            <Typography variant="h6" color="primary" gutterBottom>
              Results
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                { fetching && fetchingEl }
                { !fetching && Object.keys(results).length > 0 && resultsEl }
                { !fetching && error !== '' && errorEl }
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="primary" gutterBottom className={classes.faq}>
            Answers to questions and comments you might have:
          </Typography>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} color="primary" gutterBottom>
                Why?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                Strong verbs improve the clarity and impact of your prose, but can be tough to find with a traditional thesaurus.
                This tool attempts to do better by using vector arithmetic on pre-trained language models (e.g., "excitedly" + "laughed" = "guffawed").  
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} color="primary" gutterBottom>
                Why so slow?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <b>tl;dr</b> Data very big, memory very small. It's fastest if you use an adjective or adverb as a modifier and a verb that cannot be confused for a noun (e.g., "thought").
                <br/>
                The GloVe model I use is large (~5GB), and <a href="https://calculator.aws/#/estimate?id=a19e3effaede6efd55dc53c7f17a3e6121465d8e" target="_blank" rel="noopener noreferrer">RAM can be expensive to rent on servers</a>.
                This means I had to partition the data into many chunks and incrementally do the calculations on each chunk, which can make things slow.
                I made some minor optimizations to make this faster for the more common use-case (e.g., an adjective / adverb modifier, and a verb that cannot be confused for a noun).
                Still, here we are.
                If you'd like this to be faster and have the means,&nbsp;
                <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ULH3ZHQKWXL38&source=url" target="_blank" rel="noopener noreferrer">consider donating to help subsidize the costs</a>.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} color="primary" gutterBottom>
                This doesn't seem to be very good?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <b>tl;dr</b> The existing model can be improved, and I have some ideas, but need a lot more data. I am figuring out how to collect this data.
                <br/>
                Try using an adverb and a verb in the past tense—the model works best that way.
                It never seems to work well for non-descript verbs like "to be" and "to do", which are arguably the Weakest of Them All.
                But, yeah, it can be improved and I think I know how.
                Static word embeddings (which is what I use) depend on the <a href="https://en.wikipedia.org/wiki/Distributional_semantics" target="_blank" rel="noopener noreferrer">distributional hypothesis</a>: i.e., words that show up in similar contexts mean similar things.
                However, the in-sentence context of the word, when actually used, is ignored.
                A better approach might be to use dynamic word embeddings that takes into account the context of the word as it is being used.
                However, training such a model will require collecting many (weak verb, strong verb) tuples used in context, and I'm trying to figure out a non-cost-prohibitive way to do that now.
                If you'd like to help with these efforts and have the means,&nbsp;
                <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ULH3ZHQKWXL38&source=url" target="_blank" rel="noopener noreferrer">consider donating to help subsidize the costs</a>.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>


          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} variant="subtitle1" color="primary" gutterBottom>
                I'd like to help in a way that doesn't involve donating money.
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <a href="https://share.hsforms.com/1l2FlY5BDTeObsVEuiqzKnw4mj2d" target="_blank" rel="noopener noreferrer">Subscribe to this mailing list.</a>
                <br/>
                I'm thinking of ways I can take advantage of people's interest.
                One way might be, e.g., to do some interviews with writers who would use this tool to get their feedback.
                Another way might be having writers provide example (weak verb, strong verb) tuples to train the improved model I
                discussed in the answer above. If you're interested in helping out in these ways, please subscribe to the
                mailing list and I'll be in touch.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>


          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} variant="subtitle1" color="primary" gutterBottom>
                How can I be alerted to updates / improvements?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <a href="https://share.hsforms.com/1l2FlY5BDTeObsVEuiqzKnw4mj2d" target="_blank" rel="noopener noreferrer">Subscribe to this mailing list</a> and/or &nbsp;
                <a href="https://twitter.com/scyrusk" target="_blank" rel="noopener noreferrer">Follow me on Twitter.</a>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>


          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} variant="subtitle1" color="primary" gutterBottom>
                I have an idea for a feature that you should implement.
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <a href="https://twitter.com/scyrusk" target="_blank" rel="noopener noreferrer">@ me on Twitter.</a>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.faq} variant="subtitle1" color="primary" gutterBottom>
                Who are you?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className={classes.faq}>
                <a href="https://www.sauvik.me" target="_blank" rel="noopener noreferrer">This guy.</a>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        { solicitEl }
      </Grid>
    </div>
  );
}

export default App;
